import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'plural',
  standalone: true,
  pure: true
})
export class PluralPipe implements PipeTransform {
  transform(input: number, word: string, plural?: string): string {
    return input <= 1 ? word : word + (plural ?? 's');
  }
}
